import { Button, CircularProgress, makeStyles } from "@material-ui/core";
import React, { useState } from "react";
import AddIcon from "@material-ui/icons/Add";
import JobAddForm from "./jobAddForm";
import "react-toastify/dist/ReactToastify.css";

const useStyles = makeStyles((theme) => ({
  addButton: {
    color: 'white',
    fontSize: '3vh',
    position: 'fixed',
    bottom: '5%',
    right: '5%',
    border: '0px',
    borderRadius:'50px',
    backgroundColor: '#FF725E',
    '&:hover': {
      color: 'black',
      backgroundColor: '#FF725E',
    },
  }
}));

const JobAddButton = () => {
  const classes = useStyles();
  const [addDialogState, setAddDialogState] = useState(false);
  return (
    <React.Fragment>
      <Button startIcon={<AddIcon />}
          variant="outlined"
          onClick={() => setAddDialogState(true)}
          className={classes.addButton}
      >
          New Application    
      </Button>
      <JobAddForm open={addDialogState} onClose={() => setAddDialogState(false)} />
    </React.Fragment>
  );
};

export default JobAddButton;
