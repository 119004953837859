import React from "react";
import {Route, Switch } from "react-router";
import { BrowserRouter } from 'react-router-dom';
import { AuthProvider } from './contexts/AuthContext';
import * as Views from './views';
import PrivateRoute from "./utility/privateroute";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";



function App() {
  return (
    <BrowserRouter>
      <AuthProvider>
        <Switch>
        <PrivateRoute exact path='/dashboard' component={Views.JobBoard} />
          <Route exact path='/' component={Views.Home} />
          <Route path='/login' component={Views.SignIn} />
          <Route path='/signup' component={Views.SignUp} />
          <Route path='/forgot-password' component={Views.ForgotPassword} />
        </Switch>
      </AuthProvider>
      <ToastContainer />
    </BrowserRouter>
  );
}

export default App;
