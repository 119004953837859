import { Button, makeStyles } from "@material-ui/core";
import React, { useState } from "react";
import {useHistory } from "react-router-dom"
import {useAuth} from '../../contexts/AuthContext'
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import logo from '../../assets/logo.svg';
import "react-toastify/dist/ReactToastify.css";

const useStyles = makeStyles((theme) => ({
  toolbarButtons: {
    marginLeft: 'auto',
  },
  logoutButton: {
    backgroundColor: '#FF725E',
    color: 'white',
    textTransform: 'none',
    paddingTop: '10px',
    paddingBottom: '10px',
    paddingLeft: '20px',
    paddingRight: '20px',
    borderRadius: '50px',
    '&:hover': {
      backgroundColor: '#FF725E',
      color: 'black',
    },
  },
}));

const LoggedInNavBar = () => {
  const classes = useStyles();
  const { logout } = useAuth()
  const history = useHistory()
  const [logError, setError] = useState("")

  async function handleLogout() {
    setError("")
    try {
      await logout()
      history.push("/")
    } catch {
      setError("Log out failed")
      console.log(logError)
    }
  }

  return (
    <AppBar position="relative">
        <Toolbar>
            <img src={logo} width="150" alt="company logo"/>
            <div className={classes.toolbarButtons}>
            <Button onClick={handleLogout} className={classes.logoutButton}> Log Out </Button>
            </div>
        </Toolbar>
    </AppBar>
  );
};

export default LoggedInNavBar;
