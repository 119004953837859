import React from "react";
import CssBaseline from '@material-ui/core/CssBaseline';
import LoggedInNavBar from "../components/JobBoard/loggedInNavBar"
import JobAddButton from "../components/JobBoard/jobAddButton"
import JobCardContainer from "../components/JobBoard/jobCardContainer"

const JobBoard = () => {
  return (
    <div>
      <CssBaseline />
      <LoggedInNavBar/>
      <JobAddButton/>
      <JobCardContainer/>
    </div>
  );
};

export default JobBoard;
