import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';
import { Link as RouterLink } from 'react-router-dom';
import logo from '../../assets/logo.svg';

const useStyles = makeStyles((theme) => ({
  toolbarButtons: {
    marginLeft: 'auto',
  },
  loginButton: {
    color: 'black',
    border: '1px solid white',
    textTransform: 'none',
    paddingTop: '10px',
    paddingBottom: '10px',
    paddingLeft: '20px',
    paddingRight: '20px',
    borderRadius: '50px',
    marginRight: '10px',
    '&:hover': {
      backgroundColor: 'white',
      color: '#FF725E',
    },
  },
  startButton: {
    backgroundColor: '#FF725E',
    color: 'white',
    textTransform: 'none',
    paddingTop: '10px',
    paddingBottom: '10px',
    paddingLeft: '20px',
    paddingRight: '20px',
    borderRadius: '50px',
    '&:hover': {
      backgroundColor: '#FF725E',
      color: 'black',
    },
  },
}));

export default function LoggedOutNavBar() {
  const classes = useStyles();
  return (
    <AppBar position="relative" elevation={0}>
        <Toolbar>
            <img src={logo} width="150" alt="company logo"/>
            <div className={classes.toolbarButtons}>
                <Button component={RouterLink} className={classes.loginButton} to="/login">Login</Button>
                <Button component={RouterLink} className={classes.startButton} to="/signup">Start For Free</Button>
            </div>
    </Toolbar>
    </AppBar>
  );
}
