import React from "react";
import {
  createMuiTheme,
  responsiveFontSizes,
  ThemeProvider,
} from "@material-ui/core/styles";

const Theme: React.FC = ({ children }) => {
  const theme = responsiveFontSizes(
    createMuiTheme({
      spacing: 5,
      typography: {
        fontWeightRegular: 300,
        h4: {
          fontWeight: 300,
          fontSize: 18.2,
          // color: "#3C4858",
          marginBottom: 3,
        },
      },
      palette: {
        warning: {
          main: "rgb(255,255,255)",
        },
        primary: {
          main: "rgb(255,255,255)",
        },
        secondary: {
          main: "rgb(255,255,255)",
        },
      },
    })
  );
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export default Theme;
