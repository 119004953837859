import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import LoggedOutNavBar from '../components/Home/loggedOutNavBar';
import HomeFooter from '../components/Home/homeFooter';
import HomeHeroSection from '../components/Home/homeHeroSection';
import HomeFeatureCards from '../components/Home/homeFeatureCards';

export default function Home() {
  return (
    <React.Fragment>
      <CssBaseline />
      <LoggedOutNavBar/>
      <main>
        <HomeHeroSection/>
        <HomeFeatureCards/>
      </main>
      <HomeFooter/>
    </React.Fragment>
  );
}
