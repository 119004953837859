  import React, {useState} from 'react';
  import Avatar from '@material-ui/core/Avatar';
  import Button from '@material-ui/core/Button';
  import CssBaseline from '@material-ui/core/CssBaseline';
  import TextField from '@material-ui/core/TextField';
  import Link from '@material-ui/core/Link';
  import Paper from '@material-ui/core/Paper';
  import Box from '@material-ui/core/Box';
  import Grid from '@material-ui/core/Grid';
  import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
  import Typography from '@material-ui/core/Typography';
  import { makeStyles } from '@material-ui/core/styles';
  import {useAuth} from "../contexts/AuthContext"
  import {Alert} from 'react-bootstrap'
  import {useHistory} from 'react-router-dom'

  function Copyright() {
    return (
      <Typography variant="body2" color="textSecondary" align="center">
        {'Copyright © '}
        <Link color="inherit" href="https://trackity.info/">
        Trackity
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
    );
  }

  const useStyles = makeStyles((theme) => ({
    root: {
      height: '100vh',
    },
    image: {
      backgroundImage: 'url(https://cdn.discordapp.com/attachments/827285646212202577/844648626495160320/Manage_materials_slide.png)',
      backgroundRepeat: 'no-repeat',
      backgroundColor:
        theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
      backgroundSize: 'cover',
      backgroundPosition: 'center',

    },
    paper: {
      margin: theme.spacing(8, 4),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    avatar: {
      margin: theme.spacing(1),
      color: '#FF725E',
      backgroundColor: 'white'
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1),
      color: 'black'
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
      '&:hover': {
        backgroundColor: '#FF725E'
        }
    },
    error: {
      color: 'red'
    },
    links: {
      color: '#0645AD'
    }
  }));

  export default function SignUp() {
    const classes = useStyles();
    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [passwordConfirm, setPasswordConfirm] = useState<string>('');
    const {signup} = useAuth()
    const [error, setError] = useState("")
    const [loading, setLoading] = useState(false)
    const history = useHistory()



    async function handleSubmit(e:any) {
      e.preventDefault()

      if (password.length < 6) {
        return setError("Password is less than 6 characters")
      }

      if (password !== passwordConfirm) {
        return setError("Passwords do not match")
      }

      try {
        setError('')
        setLoading(true)
        await signup(email, password)
        history.push('/dashboard')

      } catch {
        setError('Failed to create an account')
      }

      setLoading(false)

    }




    return (
      <Grid container component="main" className={classes.root}>
        <CssBaseline />
        <Grid item xs={false} sm={4} md={7} className={classes.image}/>
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <div className={classes.paper}>
            <Avatar className={classes.avatar}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Create an Account
            </Typography>
            {error && <Alert variant="danger" className={classes.error}>{error}</Alert>}
            <form className={classes.form} onSubmit={handleSubmit} noValidate>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                onChange={event => setEmail(event.target.value)}
                value={email}
                id="email"
                label="Email Address (needs to be a legit email address e.g. johnappleseed@gmail.com)"
                name="email"
                autoComplete="email"
                // autoFocus
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                onChange={event => setPassword(event.target.value)}
                value={password}
                name="password"
                label="Password (needs to be at least 6 characters)"
                type="password"
                id="password"
                autoComplete="current-password"
                className={classes.links}
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                onChange={event => setPasswordConfirm(event.target.value)}
                value={passwordConfirm}
                name="confirmPassword"
                label="Confirm Password (needs to be at least 6 characters)"
                type="password"
                id="confirmPassword"
                autoComplete="confirm-password"
              />

              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                disabled={loading}
              >
                Sign up
              </Button>
              <Grid container>
              <Grid item xs>
                <Link href="/" variant="body2" className={classes.links}>
                  Back Home
                </Link>
              </Grid>
                <Grid item>
                  <Link href="/login" variant="body2" className={classes.links}>
                    {"Already have an account? Log In"}
                  </Link>
                </Grid>

              </Grid>
              <Box mt={5}>
                <Copyright />
              </Box>
            </form>
          </div>
        </Grid>
      </Grid>
    );
  }

