import {
    Card,
    CardContent,
    CardHeader,
    IconButton,
    makeStyles,
    Typography,
  } from "@material-ui/core";
  import React, { FC, useState} from "react";
  import format from "date-fns/format";
  import {firebase } from "../../config/firebase";
  import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
  import EditIcon from '@material-ui/icons/Edit';
  import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
  import swal from "sweetalert";
  import { toast } from "react-toastify";
  import "react-toastify/dist/ReactToastify.css";
  import JobEditForm from "./jobEditForm";
  import Collapse from "@material-ui/core/Collapse";
  import clsx from 'clsx';


  export interface JobProps {
    docRef: firebase.firestore.DocumentReference;
    jobName: "",
    jobCompany: "",
    jobType: "",
    lastStepCompleted: "",
    nextStep: "",
    appliedDate: firebase.firestore.Timestamp;
    nextStepDate: firebase.firestore.Timestamp;
    appLink: "",
    payRate: "",
    payRateSelect: "",
    location: "",
    contactdetails: "",
    notes: "",
    activeStatus: "",
    tbd: ""
  }

  const useStyles = makeStyles((theme) => ({
    card: {
      width: 345,
      "& .row": {
        display: "flex",
        "& .property": {
          paddingRight: 10,
        },

        "& .value": {
          whiteSpace: "pre-wrap",
        },
      },
    },
    open : {
      transform: "scaleY(-1)",
    },
    close: {
        transform: "scaleY(1)",
    },


  }));


  const JobCard: FC<JobProps> = (props) => {
    const classes = useStyles();
    const [addDialogState, setAddDialogState] = useState(false);
    const [expanded, setExpanded] = React.useState(false);



    const deleteJob = () => {
      swal({
        title: "Delete Job?",
        text: `Delete job named "${props.jobName}" at "${props.jobCompany}"?`,
        dangerMode: true,
        buttons: ["CANCEL", true],
      }).then((istrue) => {
        if (istrue) {
          props.docRef?.delete();
          toast("Job deleted");
        }
      });
    };

    const handleExpandClick = () => {
      setExpanded(!expanded);
    };

    return (
      <Card variant="outlined" className={classes.card} >
        <CardHeader
          title={props.jobCompany}
          action={
            <div>
              <IconButton onClick={deleteJob}>
                <DeleteForeverIcon />
              </IconButton>
              <IconButton onClick={() => setAddDialogState(true)}>
                <EditIcon />
              </IconButton>
              <IconButton
                onClick={handleExpandClick}
                aria-expanded={expanded}
                aria-label="show more"
                className={clsx(!expanded && classes.close, expanded && classes.open)}
              >
                  <ExpandMoreIcon />
              </IconButton>
            </div>
          }
          subheader={props.jobName + " " + props.jobType}

        />
        <CardContent>
        {props.nextStep && (
            <div className="row">
              <Typography variant="caption" className="property">
                Current Status:
              </Typography>

              <Typography variant="caption" className="value">
                {props.nextStep}
              </Typography>

              <Typography variant="caption" className="value">
                {format(
                  props.nextStepDate.toDate() ?? new Date(),
                  "MMMM dd, yyyy"
                )}
              </Typography>

              <Typography variant="caption" className="value">
                {props.tbd}
              </Typography>

            </div>
          )}
          {props.jobName && (
            <div className="row">
              <Typography variant="caption" className="property">
                Date Applied:
              </Typography>
              <Typography variant="caption" className="value">
                {format(
                  props.appliedDate.toDate() ?? new Date(),
                  "MMMM dd, yyyy"
                )}
              </Typography>
            </div>
          )}
          {props.lastStepCompleted && (
            <div className="row">
              <Typography variant="caption" className="property">
                Last Step Completed:
              </Typography>
              <Typography variant="caption" className="value">
                {props.lastStepCompleted}
              </Typography>
            </div>
          )}
          {props.nextStep && (
            <div className="row">
              <Typography variant="caption" className="property">
                Next Step:
              </Typography>
              <Typography variant="caption" className="value">
                {props.nextStep}
              </Typography>

            </div>
          )}


        </CardContent>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent>
            {props.appLink && (
              <div className="row">
                <Typography variant="caption" className="property">
                  Application Link:
                </Typography>
                <Typography variant="caption" className="value">
                  {props.appLink}
                </Typography>
              </div>
            )}
             {props.payRate && (
              <div className="row">
                <Typography variant="caption" className="property">
                Pay Rate:
                </Typography>
                <Typography variant="caption" className="value">
                  {props.payRate}
                </Typography>

                <Typography variant="caption" className="value">
                  {props.payRateSelect}
                </Typography>

              </div>
            )}
            {props.location && (
              <div className="row">
                <Typography variant="caption" className="property">
                  Location:
                </Typography>
                <Typography variant="caption" className="value">
                  {props.location}
                </Typography>
              </div>
            )}
            {props.contactdetails && (
              <div className="row">
                <Typography variant="caption" className="property">
                  Contact Details:
                </Typography>
                <Typography variant="caption" className="value">
                  {props.contactdetails}
                </Typography>
              </div>
            )}
            {props.notes && (
              <div className="row">
                <Typography variant="caption" className="property">
                  Notes:
                </Typography>
                <Typography variant="caption" className="value">
                  {props.notes}
                </Typography>
              </div>
            )}
          </CardContent>
        </Collapse>
        <JobEditForm open={addDialogState} onClose={() => setAddDialogState(false)} props={props}/>
      </Card>
    );
  };

  export default JobCard;

