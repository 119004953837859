import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grow,
  makeStyles,
  TextField,
  FormControl,
  TextFieldProps,
  MenuItem,
  Select,
  InputLabel,
  FormLabel,
  FormControlLabel,
  Radio,
  RadioGroup,
  IconButton,
  withWidth
} from "@material-ui/core";
import React, { ChangeEvent, FC, useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { db, firebase } from "../../config/firebase";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import "date-fns";
import {useAuth} from '../../contexts/AuthContext'
import { AutorenewTwoTone, SignalWifi1BarLockSharp } from "@material-ui/icons";
import ClearIcon from '@material-ui/icons/Clear';
import swal from "sweetalert";
import Checkbox, { CheckboxProps } from '@material-ui/core/Checkbox';
import {Alert} from 'react-bootstrap'
import Input from '@material-ui/core/Input'




const useStyles = makeStyles((theme) => ({
  textField: {
    marginBottom: 10,
  },
  prioritySection: {
    marginBottom: 10,
  },
  date: {
    display: 'flex',
    alignContent: 'left',
    marginBottom: 10,

  },

  saveButton: {
    '&:hover': {
      backgroundColor: '#FF725E'
      }

  },

  dialogTitle: {
    display: 'flex',
    justifyContent: 'space-between'



  },
  dialog: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%'

  },

  form: {




  },



  radio: {
    '&$checked': {
      color: 'black'
    }
  },
  checked: {
    '&$checked': {
      color: 'black'
    }
  },


  error: {
    color: 'red'
  },

}));

const JobAddForm: FC<{ open: boolean; onClose: () => any }> = ({
  open,
  onClose = () => {},
}) => {
  const classes = useStyles();
  const [form, setForm] = useState({
    jobName: "",
    jobCompany: "",
    jobType: "",
    lastStepCompleted: "",
    nextStep: "",
    appliedDate: firebase.firestore.Timestamp.fromDate(new Date()),
    nextStepDate: firebase.firestore.Timestamp.fromDate(new Date()),
    appLink: "",
    payRate: "",
    payRateSelect: "",
    location: "",
    contactdetails: "",
    notes: "",
    activeStatus: "Active",
    tbd: "",
  });

  const { currentUser } = useAuth()


  const defaultTextfieldProps: TextFieldProps = {
    fullWidth: true,
    variant: "outlined",
    className: classes.textField,
  };




  const save = () => {

    db
      .collection("users")
      .doc(currentUser.uid)
      .collection("jobs")
      .add(form)
      .then(() => {
        toast("Added new job");
      })
      .catch(() => {
        toast("Failed to add job card", { type: "error" });
      });
    setForm({
      ...form,
      jobName: "",
      jobCompany: "",
      jobType: "",
      lastStepCompleted: "",
      nextStep: "",
      appliedDate: firebase.firestore.Timestamp.fromDate(new Date()),
      nextStepDate: firebase.firestore.Timestamp.fromDate(new Date()),
      appLink: "",
      payRate: "",
      payRateSelect: "",
      location: "",
      contactdetails: "",
      notes: "",
      activeStatus: "Active",
      tbd: "",
    });
    onClose();

  };

  const handleDateChange = (date: Date | null) => {
    setForm({
      ...form,
      appliedDate: firebase.firestore.Timestamp.fromDate(date ?? new Date()),
    });
  };

  const handleActiveChange = (event: any) => {
    setForm({
      ...form,
      activeStatus: event.target.value
    });
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleStepCompletedChange = (event: any) => {
    setForm({
      ...form,
      lastStepCompleted: event.target.value
    });
  };

  const handleNextStepChange = (event: any) => {
    setForm({
      ...form,
      nextStep: event.target.value
    });
  };

  const handlePayRateChange = (event: any) => {
    setForm({
      ...form,
      payRateSelect: event.target.value
    });
  };

  const handleNextStepDateChange = (date: Date | null) => {
    setForm({
      ...form,
      nextStepDate: firebase.firestore.Timestamp.fromDate(date ?? new Date()),
    });
  };

  const handleJobTypeChange = (event: any) =>  {
    setForm({
      ...form,
      jobType: event.target.value
    });
  };

  const exitForm = () => {
    swal({
      title: "Unsaved Changes",
      text: `Are you sure you want to exit without saving your changes?`,
      dangerMode: true,
      buttons: ["CANCEL", true],
    }).then((istrue) => {
      if (istrue) {
        onClose();
        setForm({
          ...form,
          jobName: "",
          jobCompany: "",
          jobType: "",
          lastStepCompleted: "",
          nextStep: "",
          appliedDate: firebase.firestore.Timestamp.fromDate(new Date()),
          nextStepDate: firebase.firestore.Timestamp.fromDate(new Date()),
          appLink: "",
          payRate: "",
          payRateSelect: "",
          location: "",
          contactdetails: "",
          notes: "",
          activeStatus: "Active",
          tbd: "",
        });

      }
    });
  };


  return (
    <Dialog TransitionComponent={Grow} open={open} onClose={onClose} className={classes.dialog} >
      <div className={classes.dialogTitle}>
        <DialogTitle>New Job</DialogTitle>
        <IconButton  onClick={exitForm}>
          <ClearIcon />
        </IconButton>
      </div>

      <DialogContent className={classes.form}>

      <FormControl >
         <RadioGroup row aria-label="activeStatus" name="activeStatus"  value={form.activeStatus} onChange={handleActiveChange}>
           <FormControlLabel defaultChecked value="Active" control={ <Radio classes={{root: classes.radio, checked: classes.checked}}/>}   label="Active" />
           <FormControlLabel  value="Inactive" control={<Radio classes={{root: classes.radio, checked: classes.checked}}/>} label="Inactive" />

         </RadioGroup>
      </FormControl>

        <TextField {...defaultTextfieldProps} inputProps={{maxLength: 20,}} label="Position" name="jobName" value={form.jobName} onChange={handleChange} />

        <TextField  {...defaultTextfieldProps} inputProps={{maxLength: 12,}} label="Company" name="jobCompany" value={form.jobCompany} onChange={handleChange} />
        <InputLabel   id="priority-select-label">Job Type</InputLabel>
        <FormControl >
              <Select
                labelId="priority-select-label"
                id="priority-select"
                value={form.jobType}
                onChange={handleJobTypeChange}
                >


                <MenuItem value='Full-Time'>Full-Time</MenuItem>
                <MenuItem value='Part-Time'>Part-Time</MenuItem>
                <MenuItem value='Internship'>Internship</MenuItem>

              </Select>
        </FormControl>
        <div className={classes.date}>
        <MuiPickersUtilsProvider utils={DateFnsUtils} >
        <KeyboardDatePicker

          disableToolbar
          variant="inline"
          format="MMMM dd, yyyy"
          margin="normal"
          id="date-picker-inline"
          label="Date Applied"
          disableFuture= {true}
          value={form.appliedDate.toDate()}
          onChange={handleDateChange}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
        />
        </MuiPickersUtilsProvider>
        </div>
        <InputLabel   id="priority-select-label">Last Step Completed</InputLabel>
          <FormControl className={classes.prioritySection}>
              <Select
                labelId="priority-select-label"
                id="priority-select"
                value={form.lastStepCompleted}
                onChange={handleStepCompletedChange}
              >

                <MenuItem value='Application'>Application</MenuItem>
                <MenuItem value='Interview'>Interview</MenuItem>
                <MenuItem value='Phone interview'>Phone interview</MenuItem>
                <MenuItem value='Technical interview'>Technical interview</MenuItem>
                <MenuItem value='Coding interview'>Coding interview</MenuItem>
                <MenuItem value='Onsite Interview'>Onsite Interview</MenuItem>
                <MenuItem value='Behavioral interview'>Behavioral interview</MenuItem>
                <MenuItem value='Final interview'>Final interview</MenuItem>
                <MenuItem value='Take home test'>Take home test</MenuItem>
                <MenuItem value='Presentation'>Presentation</MenuItem>
                <MenuItem value='Pitch'>Pitch</MenuItem>
              </Select>
         </FormControl>
         <InputLabel  id="priority-select-label">Next Step</InputLabel>
         <FormControl >
              <Select
                labelId="priority-select-label"
                id="priority-select"
                value={form.nextStep}
                onChange={handleNextStepChange}
              >

                <MenuItem value='To Be Determined '>To Be Determined</MenuItem>
                <MenuItem value='Interview: '>Interview</MenuItem>
                <MenuItem value='Phone interview '>Phone interview</MenuItem>
                <MenuItem value='Technical interview '>Technical interview</MenuItem>
                <MenuItem value='Coding interview '>Coding interview</MenuItem>
                <MenuItem value='Onsite Interview '>Onsite Interview</MenuItem>
                <MenuItem value='Behavioral interview' >Behavioral interview</MenuItem>
                <MenuItem value='Final interview'>Final interview</MenuItem>
                <MenuItem value='Take home test '>Take home test</MenuItem>
                <MenuItem value='Presentation '>Presentation</MenuItem>
                <MenuItem value='Pitch '>Pitch</MenuItem>
              </Select>
         </FormControl>

         <div className={classes.date}>
         <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker

          disableToolbar
          variant="inline"
          format="MMMM dd, yyyy"
          margin="normal"
          id="date-picker-inline"
          label="Next Step Date"
          disablePast= {true}
          value={form.nextStepDate.toDate()}
          onChange={handleNextStepDateChange}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
        />
        </MuiPickersUtilsProvider>
        </div>


        <TextField {...defaultTextfieldProps} label="Application Link" name="appLink" value={form.appLink} onChange={handleChange} />
        <InputLabel id="priority-select-label">Pay Rate</InputLabel>
        <FormControl className={classes.prioritySection}>
              <Select
                labelId="priority-select-label"
                id="priority-select"
                labelWidth={5}
                value={form.payRateSelect}
                onChange={handlePayRateChange}
              >

                <MenuItem id=' Per Hour' value=' Hourly'>Hourly</MenuItem>
                <MenuItem id=' Salary' value=' Salary'>Salary</MenuItem>

              </Select>
        </FormControl>
        <TextField {...defaultTextfieldProps} inputProps={{maxLength: 7, type: 'number'} }  id='payRate' label="Enter pay (needs to be less than 7 numbers)" name="payRate" value={form.payRate} onChange={handleChange} />
        <TextField {...defaultTextfieldProps} label="Location" name="location" value={form.location} onChange={handleChange} multiline />
        <TextField {...defaultTextfieldProps} label="Contact" name="contactdetails" value={form.contactdetails} onChange={handleChange} />
        <TextField {...defaultTextfieldProps} label="Notes" name="notes" value={form.notes} onChange={handleChange} rows={5} rowsMax={10} multiline />
      </DialogContent>

      <DialogActions>

        <Button onClick={save} size="large" className={classes.saveButton} disabled={!form.jobName}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default JobAddForm;
