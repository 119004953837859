import React, { useContext, useEffect, useState} from 'react'
import { auth } from '../config/firebase'

const AuthContext = React.createContext<any>(null)


export function useAuth() {
    return useContext(AuthContext)
}

export function AuthProvider({ children }: any) {
    const[currentUser, setCurrentUser] = useState<any>()
    const[loading, setLoading] = useState(true)
    

    function signup(email:string, password:string) {
        return auth.createUserWithEmailAndPassword(email, password)
    }

    function signin(email:string, password:string) {
        return auth.signInWithEmailAndPassword(email, password)
    }

    function logout() {
        return auth.signOut()
    }

    function resetPassword(email:string) {
        return auth.sendPasswordResetEmail(email)
    }

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(user => {
            setCurrentUser(user)
            setLoading(false)
            
        })
        return unsubscribe
    }, [])


    const value = {
        currentUser,
        signup,
        signin,
        logout,
        resetPassword
    }

    return(
        <AuthContext.Provider value={value}>
            {!loading && children}
        </AuthContext.Provider>
    )
}