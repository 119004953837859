import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Apps from '../../assets/apps.png'
import Illust from '../../assets/illust.png'
import Icons from '../../assets/icons.png'


<link href="https://fonts.googleapis.com/css?family=Chivo:300" rel="stylesheet"></link>

const useStyles = makeStyles((theme) => ({
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    
    
  },
  cardMedia: {
    height: '100%',
    paddingTop: '56.25%', // 16:9
  },
  cardContent: {
    flexGrow: 1,
   
   
  },

  body: {
    fontFamily: 'Chivo, sans-serif',
    fontWeight: 300,

  }
}));

export default function HomeFeatureCards() {
  const classes = useStyles();
  return (
    <Container className={classes.cardGrid} maxWidth="md">
      <Grid container spacing={4}>
        <Grid item xs={12} sm={6} md={4}>
              <Card className={classes.card}>
                <CardMedia
                  className={classes.cardMedia}
                  image={Apps}
                  title="Save your job opportunities easily."
                />
                <CardContent className={classes.cardContent}>
                  <Typography className={classes.body} gutterBottom variant="h5" component="h2">
                  Save your job opportunities easily.
                  </Typography>
                  <Typography className={classes.body}>
                  Start by inputting everything you know about a job opportunity you have applied for, and we will help organize your entire recruiting process.
                  </Typography>
                </CardContent>
              </Card>
          </Grid>
        <Grid item xs={12} sm={6} md={4}>
              <Card className={classes.card}>
                <CardMedia
                  className={classes.cardMedia}
                  image={Illust}
                  title="Track your job application status"
                />
                <CardContent className={classes.cardContent}>
                  <Typography className={classes.body} gutterBottom variant="h5" component="h2">
                  Track your job application status
                  </Typography>
                  <Typography className={classes.body}>
                  Trackity will set the status of your applications to manage the stages of each job’s recruitment status and your next steps
                  </Typography>
                </CardContent>
              </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
              <Card className={classes.card}>
                <CardMedia
                  className={classes.cardMedia}
                  image={Icons}
                  title="Manage required materials"
                />
                <CardContent className={classes.cardContent}>
                  <Typography className={classes.body} gutterBottom variant="h5" component="h2">
                  Manage required materials
                  </Typography>
                  <Typography className={classes.body}>
                  Note anything from application materials to new connections,  helping you to successfully put your best foot foward. 
                  </Typography>
                </CardContent>
              </Card>
          </Grid>
        </Grid>
    </Container>
  );
}
