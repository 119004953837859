import { CircularProgress, makeStyles } from "@material-ui/core";
import React from "react";
import { useCollection } from "react-firebase-hooks/firestore";
import { db } from "../../config/firebase";
import {useAuth} from '../../contexts/AuthContext'
import JobCard, { JobProps } from "./jobCard";
import Typography from '@material-ui/core/Typography'
import "react-toastify/dist/ReactToastify.css";
<link href="https://fonts.googleapis.com/css?family=Quicksand:500" rel="stylesheet"></link>
import '../Home/homeFeatureCards'

const useStyles = makeStyles((theme) => ({
  cardContainer : {
    marginTop: "2%",
    marginLeft: "5%",
    marginRight: "5%"
  },
  noJob: {},
  loading: {},
  jobs: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "flex-start",
    "& > *": {
      marginBottom: "2%",
      marginRight: "1%"
    },
  },
  sectionHeader: {
    fontFamily: 'Quicksand',
    fontWeight: 500,
  },
}));

const JobCardContainer = () => {
  const classes = useStyles();
  const { currentUser } = useAuth()
  const [jobs, loading] = useCollection(db.collection("users").doc(currentUser.uid).collection("jobs"));

  return (
    <div className={classes.cardContainer}>
        {loading ? (
        <CircularProgress />
        ) : jobs?.docs.length ? (
            <div>
            <Typography variant="h4" component="h2" className={classes.sectionHeader}> Active </Typography>
            <div className={classes.jobs}  >
                {jobs?.docs.map((doc) => (
                doc.data().activeStatus === "Active" ? 
                <JobCard
                   
                    {...(doc.data() as JobProps)}
                    docRef={doc.ref} 
                    key={doc.id} /> : null
                    

                   
                ))}
            </div>
            <Typography variant="h4" component="h2" className={classes.sectionHeader}> Inactive </Typography>
            <div className={classes.jobs} >
                {jobs?.docs.map((doc) => (
                    doc.data().activeStatus !== "Active" ? 
                    <JobCard 
                    
                    {...(doc.data() as JobProps)}
                    docRef={doc.ref} 
                    key={doc.id} /> : null
                ))}
            </div>
            </div>
        ) : (
        <div className={classes.noJob}>No Applications Yet</div>
        )}
    </div>
  );
};

export default JobCardContainer;
