import React from 'react';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import iSchoolLogo from '../../assets/ischoollogo.png';
import Link from '@material-ui/core/Link';

<link href="https://fonts.googleapis.com/css?family=Chivo:300" rel="stylesheet"></link>

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="right">
      {'Copyright © '}
      <Link color="inherit" href="https://material-ui.com/">
        Trackity
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  toolbarButtons: {
    marginLeft: 'auto',
  },
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6),
    

  },
  endingLogo: {
    paddingTop: '2%',
    paddingBottom: '2%'
  },
  
  body: {
    fontFamily: 'Chivo, sans-serif',
    fontWeight: 300,

  }

}));

export default function HomeFooter() {
  const classes = useStyles();
  return (
      <footer className={classes.footer}>
        <Toolbar>
          <img src={iSchoolLogo} alt="ischoollogo" className={classes.endingLogo}/>
          <div className={classes.toolbarButtons}>
            <Typography className={classes.body} variant="subtitle1" align="right" color="textSecondary" component="p">
            A Project for INFO442 by Justin Banusing, Jacky Yiu Chung Cheung, Ifrah Mohamed, Maria Matlick
            </Typography>
            <Typography variant="h6" gutterBottom>
              <Copyright/>
            </Typography>
          </div>
        </Toolbar>
      </footer>
  );
}
