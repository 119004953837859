import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

<link href="https://fonts.googleapis.com/css?family=Chivo:300" rel="stylesheet"></link>

const useStyles = makeStyles((theme) => ({
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 6),

  },
  heroButtons: {
    marginTop: theme.spacing(4),
  },

  body: {
  
    fontWeight: 300,

  }
}));

export default function HomeHeroSection() {
  const classes = useStyles();
  return (
      <div className={classes.heroContent}>
      <Container maxWidth="sm">
        <Typography className={classes.body} component="h1" variant="h2" align="center" color="textPrimary" gutterBottom>
        Organize your job applications quickly.
        </Typography>
        <Typography className={classes.body} variant="h5" align="center" color="textSecondary" paragraph>
        Meet Trackity: the comprehensive job application and interview tracker that meets every need of the modern undergraduate. 
        Never lose track of important job application and interview information again.
        </Typography>
      </Container>
    </div>
  );
}
