import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";

firebase.initializeApp({
  apiKey: "AIzaSyAwjAB3GtrUdvyLNkb0WRbL3W8uRVCttac",
  authDomain: "hurricanes-project-8b4a6.firebaseapp.com",
  projectId: "hurricanes-project-8b4a6",
  storageBucket: "hurricanes-project-8b4a6.appspot.com",
  messagingSenderId: "88168153889",
  appId: "1:88168153889:web:9b9aedf69f3235bb89f460",
  measurementId: "G-K7192TGSY6"
})

const storage = firebase.storage();
const db = firebase.firestore();
const auth = firebase.auth();

// if (window.location.hostname === "localhost") {
//   firestore.useEmulator("localhost", 8080);
//   auth.useEmulator("http://localhost:9099/");
// }  

export default firebase;
export { storage, db, auth, firebase };
